const timeReset = (mytime) => {
  var date = new Date(mytime)
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  var d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  var h = date.getHours()
  var minute = date.getMinutes()
  minute = minute < 10 ? ('0' + minute) : minute
  const time = y + '年' + m + '月' + d + '日' + ' ' + h + ':' + minute
  return time
}
export default timeReset
