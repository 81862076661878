<template>
  <div>
    <Topbar />
    <Menu />
    <div class="comment-container">
      <el-card class="input-content" shadow="never">
        <div class="message-label-question">问题反馈</div>
        <el-form :model="messageForm" label-width="80px">
          <el-form-item label="问题内容">
            <div style="display:flex;">
              <el-input
                v-model="messageForm.content"
                placeholder="说点儿什么吧"
                style="margin-right: 20px"
              />
            </div>
          </el-form-item>

          <el-form-item label="图片上传">
            <!--            <el-upload-->
            <!--              style="display: flex"-->
            <!--              class="avatar-uploader"-->
            <!--              action="fakeUploadPath"-->
            <!--              :http-request="uploadSectionFile"-->
            <!--              :show-file-list="false"-->
            <!--            >-->
            <!--              <el-image v-if="messageForm.image" :src="messageForm.image" class="avatar" :fit="'contain'" />-->
            <!--              <i v-else class="el-icon-plus avatar-uploader-icon" />-->
            <!--            </el-upload>-->

            <el-upload
              ref="upload"
              style="display: flex"
              list-type="picture-card"
              :limit="5"
              action="fakeUploadPath"
              :file-list="fileList"
              :on-remove="handlePictureRemove"
              :on-exceed="exceedTips"
              :http-request="uploadSectionFile"
              :on-success="uploadPicUrl"
              :on-change="handleChange"
              :before-upload="handleBeforeUpload"
              class="avatar-uploader"
              accept=".jpg,.jpeg,.png,.gif"
            >
              <i class="el-icon-plus" />
              <!--      <el-image v-if="form[url]" :src="form[url]" class="avatar" alt="" style="width: 145px; height: 145px" :fit="'scale-down'" />-->
              <!--      <i v-else class="el-icon-plus avatar-uploader-icon" />-->
            </el-upload>
          </el-form-item>

          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
          >
            <div style="text-align: center">微信扫描二维码反馈您的问题</div>
            <img src="../../assets/images/vxcode.jpg" style="width: 200px">
            <el-button slot="reference" class="raiseMessage" size="small" type="primary">发表</el-button>
          </el-popover>
        </el-form>

      </el-card>
      <div>
        <div class="message-label">问题总览</div>
        <!--        <el-button type="primary" size="mini" @click="scrollDown">发起问题</el-button>-->
      </div>
      <div class="comment-border">
        <!--        <div>共{{ totalComment }}条问题</div>-->
        <div v-for="(item,index) in messageList" :key="index" class="comment-box">
          <div style="display:flex;">
            <div class="comment-detail">
              <div style="display: flex; justify-content: flex-end; ">
                <el-tag v-show="item.status === 0" type="warning"> 正在处理中 </el-tag>
                <el-tag v-show="item.status === 1" type="success"> 已完成 </el-tag>
              </div>

              <el-link style="justify-content: flex-start; width: fit-content; font-size: 18px" @click="toMessageDetail(item)">{{ item.content }}</el-link>
              <div style="display: flex; flex-wrap: wrap">
                <div v-for="(images,index1) in item.image" :key="index1">
                  <el-image v-if="images" :src="images" style="width: 150px; height:150px; margin-right: 20px" :preview-src-list="[images] " :fit="'contain'" />
                </div>
              </div>

              <div class="comment-footer">
                <div style="font-size: 14px">{{ item.createTime | formatDate }}</div>
                <div style="color: dodgerblue; cursor:pointer; margin-left: 25px " @click="toMessageDetail(item)">详情</div>
              </div>
            </div>
          </div>

          <!--          <div v-for="(item1,index1) in item.reply" :key="index1" class="reply-container">-->
          <!--            <div style="display: flex;">-->
          <!--              <div>{{ item1.comment }}</div>-->
          <!--            </div>-->
          <!--            <div class="comment-footer">-->
          <!--              <div style="font-size: 14px">{{ item.createTime|formatDate }}</div>-->
          <!--              <div style="color: dodgerblue; cursor:pointer; margin-left: 25px " @click="showReply(item)">回复</div>-->

          <!--            </div>-->
          <!--          </div>-->

          <div v-show="item.showReply===true" class="replyContainer">
            <el-input
              v-model="comment"
              type="textarea"
              autosize
              placeholder="请输入内容"
              style="margin-right: 10px"
            />

            <el-button size="mini" type="primary">回复</el-button>

          </div>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Topbar from '../../components/topbar'
import Menu from '../../components/menu'
import Footer from '../../components/footer/index.vue'
import { createMessage, getMessageList, uploadToMinio } from '../../utils/api'
import { mapGetters } from 'vuex'

export default {
  name: 'Message',
  components: {
    Topbar,
    Menu,
    Footer
  },
  data() {
    return {
      picFile: [],
      fileList: [],
      listQuery: {
        size: 10,
        current: 1,
        startTime: null,
        endTime: null,
        status: 0
      },
      comment: '',
      messageForm: {
        userId: null,
        content: '',
        image: []
      },
      messageList: []
    }
  },

  computed: {
    ...mapGetters([
      'token'
    ])
  },

  created() {
    this.fetchList()
  },

  methods: {
    fetchList() {
      getMessageList(this.listQuery).then(res => {
        res.data.data.records.forEach(v => {
          if (v.content.length > 100) { // 如果字符长度超过10，后面的字符就变成...可自行调整长度和代替字符
            v.content = v.content.substr(0, 100) + '...' // 截取从第一个字符开始，往后取10个字符，剩余的用...代替
          }
        })
        this.messageList = res.data.data.records
      })
    },
    raiseMessage() {
      if (this.messageForm.content === '') {
        this.$message.warning('请输入问题')
      } else {
        createMessage(this.messageForm).then(res => {
          this.fetchList()
          this.$message.success('发起成功')
        })
      }
    },

    scrollDown() {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      this.$nextTick(() => {
        // 滚动内容的坐标位置0,50000：
        window.scrollTo(0, scrollHeight)
      })
    },

    showReply(item) {
      this.$set(item, 'showReply', true)
    },

    toMessageDetail(item) {
      this.$router.push({ path: 'message/detail', query: { id: item.id }})
    },

    handlePreview(file) {
    },

    uploadSectionFile(params) {
      const file = params.file
      const fileType = file.type
      const isImage = fileType.indexOf('image') !== -1
      if (!isImage) {
        this.$message.error('只能上传图片格式png、jpg、gif!')
      }
      const form = new FormData()
      form.append('upload', file)
      uploadToMinio(form).then(res => {
        this.messageForm.image.push(res.data.data)
        // this.picFile.push(res.data.data)
        // console.log(this.picFile)
      })
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    handleRemove(file, fileList) {
    },

    exceedTips() {
      this.$message.error('最多只能上传五张图片')
    },

    handlePictureRemove(file, fileList) {
      const removeUid = file.uid
      const index = this.picFile.indexOf(removeUid)
      this.messageForm.image.splice(index, 1)
    },

    uploadPicUrl(response) {

    },

    handleChange(file, fileList) {
    },

    handleBeforeUpload(file) {
      this.picFile.push(file.uid)
    }
  }
}
</script>

<style scoped>
.comment-container {
  background: #f5f6fa;
  min-width: 1200px;
  overflow-x: hidden;
  margin: 0;
  padding:  0;
}
.comment-border {

  text-align: start;
  font-size: 15px;
  color: #767070;
}

.comment-box {
  padding: 1% 20%;
  margin-top: 10px;
}

.comment-footer {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
}

.comment-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  word-break: break-all;
  background: white;
  padding: 20px;

}
.input-content {
  margin:  1% 20%;
  position: relative;
}
.raiseMessage {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.message-border {
  padding: 2% 25%;

}
.message-label-question {
  text-align: start;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.message-label {
  margin:  1% 20%;

  text-align: start;
  font-size: 18px;
  font-weight: bold;
}

</style>
