<template>
  <div>
    <div class="details">
      <Topbar />
      <Menu />

      <div class="oldArticleContainer" :style="{ 'background-image': 'url(' + imgUrl + ')', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%', 'background-color':backColor }">
        <div class="article">
          <div class="art-title">{{ article.articleTitle }}</div>
          <div class="art-time">{{ article.createTime | formatDate }}</div>
          <div class="art-content" v-html="article.articleContent" />
        </div>
        <el-pagination
          small
          layout="prev, pager, next"
          :total="size"
          :page-size="1"
          :current-page="article.current"
          @current-change="handlePage"
        />
        <div class="art-share">
          <div>分享:</div>
          <share :config="config" />
          <div class="social-share" />
        </div>
        <div v-show="article.comment === 1" class="comment-container">
          <div class="art-comment">
            <el-input
              v-model="comment"
              type="textarea"
              autosize
              placeholder="请输入内容"
              style="margin-right: 10px"
            />

            <el-button size="mini" type="primary" @click="handleCommit">评论</el-button>
          </div>
          <div class="comment-border">
            <div>共{{ totalComment }}条评论</div>
            <div v-for="(item,index) in commentList" :key="index" class="comment-box">
              <div style="display:flex;">

                <div class="comment-detail">

                  <div>{{ item.comment }}</div>

                  <div class="comment-footer">
                    <div style="font-size: 14px">{{ item.createTime | formatDate }}</div>
                    <!--                  <div style="color: dodgerblue; cursor:pointer; margin-left: 25px " @click="showReply(item)">回复</div>-->

                  </div>

                </div>
              </div>

              <div v-for="(item1,index1) in item.reply" :key="index1" class="reply-container">
                <div style="display: flex;">
                  <div>{{ item1.comment }}</div>
                </div>
                <div class="comment-footer">
                  <div style="font-size: 14px">{{ item.createTime|formatDate }}</div>
                  <div style="color: dodgerblue; cursor:pointer; margin-left: 25px " @click="showReply(item)">回复</div>

                </div>
              </div>
              <div v-show="item.showReply===true" class="replyContainer">
                <el-input
                  v-model="comment"
                  type="textarea"
                  autosize
                  placeholder="请输入内容"
                  style="margin-right: 10px"
                />
                <el-button size="mini" type="primary">回复</el-button>
              </div>
              <el-divider />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Topbar from '../../components/topbar/index.vue'
import Menu from '../../components/menu/index.vue'
import {
  addModCommit,
  addNavCommit,
  getModCommitList,
  getNavCommitList,
  modeArtDetails,
  navArtDetails
} from '../../utils/api'
import Share from 'vue-social-share'
import Footer from '../../components/footer'

export default {
  components: {
    Topbar,
    Menu,
    Share,
    Footer
  },

  data() {
    return {
      backColor: '',
      imgUrl: '',
      size: 0,
      comment: '',
      totalComment: 0,
      commentList: [],
      articleId: this.$route.query.articleId,
      inputShow: false,
      article: {
        current: 1
      },
      config: {
        link: location.href,
        sites: ['qq', 'weibo', 'wechat'], // 启用的站点
        // disabled: ['google', 'facebook', 'twitter'], // 禁用的站点
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },

      commitForm: {
        id: null,
        articleId: '',
        comment: '',
        parentId: null,
        reply: null
      },

      navigationForm: {
        id: null,
        articleId: '',
        comment: '',
        parentId: null
      }
    }
  },

  created() {
    this.init()
  },
  methods: {
    init() {
      const form = new FormData()
      form.append('id', this.articleId)
      if (this.$route.query.propName === 'mode') {
        modeArtDetails(this.articleId, this.article.current).then((res) => {
          this.article = res.data.data
          this.size = res.data.data.size
          this.article.articleContent = this.article.articleContent.replace(/<img/g, "<img style='max-width:800px;height:auto;display: block; margin-left: auto; margin-right: auto;'")
          if (this.article.color === 1) {
            this.imgUrl = this.article.backgroundFile
          }
          if (this.article.color === 0) {
            this.imgUrl = ''
            this.backColor = this.article.articleSubject
          }
          getModCommitList(res.data.data.id).then(res => {
            this.commentList = res.data.data
            this.totalComment = res.data.data.length
          })
        })
      } else if (this.$route.query.propName === 'nav') {
        navArtDetails(this.articleId, this.article.current).then((res) => {
          this.article = res.data.data
          this.size = res.data.data.size
          this.article.articleContent = this.article.articleContent.replace(/<img/g, "<img style='max-width:800px;height:auto;display: block; margin-left: auto; margin-right: auto;'")

          if (this.article.color === 1) {
            this.imgUrl = this.article.backgroundFile
          }
          if (this.article.color === 0) {
            this.imgUrl = ''
            this.backColor = this.article.articleSubject
          }
          getNavCommitList(res.data.data.id).then(res => {
            this.commentList = res.data.data
            this.totalComment = res.data.data.length
          })
        })
      }
    },

    reloadPage() {
      const originalUrl = location.href
      if (location.href.indexOf('#reload') === -1) {
        location.href = location.href + '#reload'

        location.reload()
      } else {
        location.href = originalUrl
      }
    },

    handlePage(val) {
      this.article.current = val
      this.init()
      window.scrollTo(0, 0)
    },

    handleCommit() {
      this.commitForm.articleId = this.article.id
      this.navigationForm.articleId = this.article.id
      if (this.$route.query.propName === 'nav') {
        this.navigationForm.comment = this.comment
        addNavCommit(this.navigationForm).then(() => {
          this.$message.success('评论成功')
          getNavCommitList(this.articleId).then(res => {
            this.commentList = res.data.data
            this.totalComment = res.data.data.length
          })
        })
      }
      if (this.$route.query.propName === 'mode') {
        this.commitForm.comment = this.comment
        addModCommit(this.commitForm).then(() => {
          this.$message.success('评论成功')
          getModCommitList(this.articleId).then(res => {
            this.commentList = res.data.data
            this.totalComment = res.data.data.length
          })
        })
      }
    },

    showReply(item) {
      this.$set(item, 'showReply', true)
    }
  }
}
</script>

<style  scoped>
.details {
  min-height: calc(100vh - 200px);
}

.oldArticleContainer {
  width: 1220px;
  margin: 0 auto;
}

.newArticleContainer {
  width: 1220px;
  margin: 0 auto;
}

.article {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-top: 20px;
}

.art-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 18px;
}

.art-time {
  font-size: 12px;
  color: #999;
  margin-bottom: 25px;
}

.art-content {
  font-size: 16px;
  text-align: left;
}

.comment-container {
  width: 800px;
  margin: 50px auto;
}

.comment-border {
  text-align: start;
  font-size: 15px;
  color: #767070
}
.comment-box {
  margin-top: 45px;
}

.comment-footer {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
}

.comment-detail {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.reply-container {
  margin-left: 50px;
}
.art-comment {

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.comment-box {
  display: flex;
  flex-direction: column;
}

.replyContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.art-share {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 30%;
}

.art-share > div {
  margin-left: 20px;
}

.art-share > div:nth-of-type(1) {
  width: 48px;
  height: 25px;
  font-size: 12px;
  color: #999;
  line-height: 25px;
  vertical-align: text-top;
}

.art-share > div > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
