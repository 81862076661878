<template>
  <div>
    <Topbar />
    <Menu />

    <div style="padding: 5% 15%">
      <div class="question-content">
        <div style="position: absolute; right: 0">
          <span v-show="questionVo.status === 0" style="float: right; color: #409EFF; font-weight: bold"> 正在处理中 </span>
          <span v-show="questionVo.status === 1" style="float: right; color: #33b045; font-weight: bold"> 处理完成 </span>

        </div>
        <div class="label">问题描述</div>
        <div style="display: flex; justify-content: left; margin-bottom: 30px">
          <div><el-image :src="userDetail.avatar" style="width: 50px;" /></div>
          <div style="font-size: 18px; font-weight: bold">{{ userDetail.username }}</div>

        </div>
        <div class="reply-content">{{ questionVo.content }}</div>
        <div style="display: flex;flex-wrap: wrap">
          <div v-for="(images,index1) in questionVo.image" :key="index1">
            <el-image v-if="images" :src="images" style="width: 150px; height:150px; margin-right: 20px" :preview-src-list="[images] " :fit="'contain'" />
          </div>
        </div>
      </div>

      <div class="question-content">
        <div class="label">客服回复</div>
        <div class="reply-content">{{ questionVo.reply }}</div>
        <div v-show="questionVo.reply!==null" class="comment-footer">
          <div style="font-size: 14px">{{ questionVo.replyTime|formatDate }}</div>
          <!--          <div style="color: dodgerblue; cursor:pointer; margin-left: 25px ">回复 </div>-->
        </div>
        <div style="display: flex">
          <div v-for="(images,index1) in questionVo.replyImage" :key="index1">
            <el-image v-if="images" :src="images" style="width: 150px; height:150px; margin-right: 20px" :preview-src-list="[images] " :fit="'contain'" />
          </div>
        </div>
      </div>

      <!--      <div class="comment-container">-->
      <!--        <div v-for="(item, index) in questionVo.commentVoList" :key="index">-->
      <!--          <div class="comment-box">-->
      <!--            <div style="width: fit-content;">-->
      <!--              <div>用户: {{ item.content }}</div>-->
      <!--            </div>-->
      <!--            <div style="display: flex">-->
      <!--              <div style="font-size: 14px">{{ item.createTime|formatDate }}</div>-->
      <!--              &lt;!&ndash;              <el-link type="primary" style=" margin:0 25px">回复</el-link>&ndash;&gt;-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="comment-img">-->
      <!--            <el-image v-if="item.image" :src="item.image" style="width: 150px;" :preview-src-list="[item.image]" />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="comment-container">
        <el-card v-for="(item, index) in questionVo.commentVoList" :key="index" style="margin-top: 10px">
          <div class="comment-box">
            <div class="comment-border">
              <div class="comment-box-top-title">
                <div style="display:flex;">
                  <el-image :src="item.avatar" style="width: 50px" />
                  <div style="margin-left: 25px">{{ item.username }}</div>
                </div>
              </div>

              <div style="display: flex; align-items: center; width: 180px;">
                <div style="font-size: 14px;">{{ item.createTime|formatDate }}</div>
                <!--              <el-link type="danger" @click="handleDeleteComment(item)">删除</el-link>-->
              </div>
            </div>

          </div>
          <div class="comment-img">
            <div style="width: 100%; text-align: left; margin-bottom: 20px">{{ item.content }}</div>
            <div style="display: flex">
              <div v-for="(images,index1) in item.image" :key="index1">
                <el-image v-if="images" :src="images" style="width: 150px; height:150px; margin-right: 20px" :preview-src-list="[images] " :fit="'contain'" />
              </div>
            </div>
          </div>

          <div v-for="(item2,index2) in item.replyList" :key="index2">
            <div class="reply-box">
              <div style="margin-bottom: 10px">
                <div>系统管理: {{ item2.content }}</div>
              </div>
              <div style="display:flex; align-items: center;">
                <div style="font-size: 14px; margin-right: 12px">{{ item2.createTime | formatDate }}</div>
              </div>
            </div>
            <div class="comment-img">
              <el-image v-if="item2.image" :src="item2.image" style="width: 150px;" :preview-src-list="[item2.image] " />
            </div>
          </div>
        </el-card>

      </div>

      <!--      <el-card class="input-content" shadow="never">

        <el-form :model="commentForm" label-width="80px">
          <el-form-item label="回复内容">
            <div style="display:flex;">
              <el-input
                v-model="commentForm.content"
                placeholder="请输入内容"
                style="margin-right: 20px"
              />
              <el-button size="small" type="primary" @click="handleReplyComment">回复</el-button>
            </div>
          </el-form-item>

          <el-form-item label="图片上传">
            <el-upload
              ref="upload"
              style="display: flex"
              class="avatar-uploader"
              list-type="picture-card"
              :limit="5"
              action="fakeUploadPath"
              :file-list="fileList"
              :on-remove="handlePictureRemove"
              :on-exceed="exceedTips"
              :http-request="uploadSectionFile"
              :on-change="handleChange"
              :before-upload="handleBeforeUpload"
              accept=".jpg,.jpeg,.png,.gif"
            >
              <i class="el-icon-plus" />
              &lt;!&ndash;      <el-image v-if="form[url]" :src="form[url]" class="avatar" alt="" style="width: 145px; height: 145px" :fit="'scale-down'" />&ndash;&gt;
              &lt;!&ndash;      <i v-else class="el-icon-plus avatar-uploader-icon" />&ndash;&gt;
            </el-upload>
          </el-form-item>
        </el-form>

      </el-card>-->
      <div />
    </div>
  </div>
</template>

<script>
import Topbar from '../../components/topbar'
import Menu from '../../components/menu'
import { deleteQuestionComment, getUserDetail, questionList, replyComment, uploadToMinio } from '../../utils/api'

export default {
  components: { Topbar, Menu },
  data() {
    return {
      picFile: [],
      showInput: false,
      id: null,
      fileList: [],
      active: 2,
      questionVo: {},
      userDetail: {},
      commentForm: {
        content: '',
        image: [],
        questionId: null
      }
    }
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.id = this.$route.query.id
      questionList(this.id).then(res => {
        this.questionVo = res.data.data
        if (this.questionVo.reply !== null) {
          this.active = 3
        }
        getUserDetail(res.data.data.userId)
          .then(r => {
            this.userDetail = r.data.data
          })
      })
    },

    uploadSectionFile(params) {
      const file = params.file
      const fileType = file.type
      const isImage = fileType.indexOf('image') !== -1
      if (!isImage) {
        this.$message.error('只能上传图片格式png、jpg、gif!')
      }
      const form = new FormData()
      form.append('upload', file)
      uploadToMinio(form).then(res => {
        this.commentForm.image.push(res.data.data)
      })
    },

    handleReplyComment() {
      this.commentForm.questionId = this.id
      if (this.commentForm.content !== '') {
        replyComment(this.commentForm).then(res => {
          this.$message.success('回复成功')
          this.init()
        })
      } else {
        this.$message.warning('请输入回复内容')
      }
    },

    handleDeleteComment(item) {
      deleteQuestionComment(item.id).then(res => {
        this.init()
      })
    },

    handleShowInput(item) {
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      this.$nextTick(() => {
        // 滚动内容的坐标位置0,50000：
        window.scrollTo(0, scrollHeight)
      })
    },

    exceedTips() {
      this.$message.error('最多只能上传五张图片')
    },

    handlePictureRemove(file) {
      // if (file.response !== undefined) {
      //   const removeUrl = file.response.data.url
      //   const index = this.fileList.indexOf(removeUrl)
      //   if (index > -1) {
      //     this.replyForm.image = this.fileList.splice(1, index)
      //   }
      // } else {
      //   const _removeUrl = file.url
      //   const _index = this.fileList.indexOf(_removeUrl)
      //   if (_index > -1) {
      //     this.replyForm.image = this.fileList.splice(1, _index)
      //   }
      // }
      const removeUid = file.uid
      const index = this.picFile.indexOf(removeUid)
      this.commentForm.image.splice(index, 1)
    },

    handleChange(file, fileList) {
    },

    handleBeforeUpload(file) {
      this.picFile.push(file.uid)
    }

  }
}
</script>

<style scoped>
.question-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 25px;
  position: relative;
}

.reply-box {
  display: flex;
  justify-content: space-between;
}

.input-content{

  margin-top: 50px;
}

.label {
  width: fit-content;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.reply-content {
  //width: fit-content;
  display: flex;
}

.reply-img {
  width: 120px;
  height: 120px;
}

.comment-img{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.comment-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;
  width: inherit;
}

.comment-box-top-title {
  font-weight: bolder;
  width: 220px;
}

.comment-border {
   display:flex;
   justify-content:space-between;
   width: 100%;
  align-items: center;
 }

.comment-footer {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
}

.avatar-uploader .avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;

}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

</style>
