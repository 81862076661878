<template>
  <div>
    <Topbar />
    <Menu />
    <div class="more-content">
      <div class="catalog">{{ modeName }}</div>
      <div v-loading="loading" class="article">
        <div
          v-for="item in artList"
          :key="item.id"
          class="article-item"
          @click="toDetails(item.id,item.type)"
        >
          <div>
            <div class="article-name">{{ item.articleTitle }}</div>
            <div class="article-time">{{ timeReset(item.createTime) }}</div>
          </div>
          <div class="article-content" v-html="item.articleContent" />
          <div class="artline" />
        </div>
      </div>
      <!--      <el-pagination-->
      <!--        background-->
      <!--        small-->
      <!--        :current-page.sync="moreList.current"-->
      <!--        layout="prev, pager, next, jumper"-->
      <!--        :total="moreList.total"-->
      <!--        :page-size="20"-->
      <!--        @size-change="handleSizeChange"-->
      <!--        @current-change="handleCurrentChange"-->
      <!--      />-->

      <el-pagination
        v-show="$route.query.propName === 'mode' && total > 0"
        small
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="modeListQuery.current"
        @current-change="handlePage"
      />

      <el-pagination
        v-show="$route.query.propName === 'nav' && total > 0"
        small
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="modeListQuery.current"
        @current-change="handleNavPage"
      />

    </div>
    <Footer />
  </div>
</template>

<script>
import Topbar from '../../components/topbar/index.vue'
import Menu from '../../components/menu/index.vue'
import Footer from '../../components/footer/index.vue'
import { modeArtContent, navArtContent, search } from '../../utils/api'
import timeReset from '@/utils/timereset'

export default {
  components: {
    Topbar,
    Menu,
    Footer
  },
  data() {
    return {
      loading: true,
      searchKey: '',
      modeName: '',
      modeId: '',
      artList: [],
      total: 0,
      current: 0,
      moreList: '',

      modeListQuery: {
        id: '',
        current: 1,
        size: 10
      },

      navListQuery: {
        id: '',
        current: 1,
        size: 10
      },
      searchListQuery: {
        keyword: ''
      }
    }
  },

  watch: {
    $route: {
      handler: function(val) {
        this.init()
      },
      // 深度观察监听
      deep: true
    }
  },

  created() {
    this.init()
    window.scrollTo(0, 0) // if (this.artList) {
    //   this.total = this.artList.length
    // }
  },
  methods: {
    timeReset,

    init() {
      this.loading = true
      if (this.$route.query.propName === 'mode') {
        this.modeName = this.$route.query.name
        this.modeId = this.$route.query.id
        this.modeListQuery.id = this.$route.query.id
        modeArtContent(this.modeListQuery).then((res) => {
          this.artList = res.data.data.records
          this.moreList = res.data.data
          this.total = res.data.data.total
          this.artList.forEach(ele => {
            ele.articleContent = ele.articleContent.replace(/<.*?>/g, '')
            if (ele.articleContent.length > 80) {
              ele.articleContent = ele.articleContent.substr(0, 80) + '...'
            }
          })
          this.loading = false
        })
      } else if (this.$route.query.propName === 'nav') {
        this.modeName = this.$route.query.name
        this.navigationId = this.$route.query.id
        this.navListQuery.id = this.$route.query.id
        navArtContent(this.navListQuery).then((res) => {
          this.artList = res.data.data.records
          this.moreList = res.data.data
          this.total = res.data.data.total
          this.artList.forEach(ele => {
            ele.articleContent = ele.articleContent.replace(/<.*?>/g, '')
            if (ele.articleContent.length > 80) {
              ele.articleContent = ele.articleContent.substr(0, 80) + '...'
            }
          })
          this.loading = false
        })
      } else if (this.$route.query.propName === 'search') {
        this.modeName = this.$route.query.name
        this.modeId = this.$route.query.id
        this.searchListQuery.keyword = this.$route.query.searchKey
        search(this.searchListQuery.keyword).then(res => {
          this.artList = res.data.data
          this.moreList = res.data.data
          this.artList.forEach(ele => {
            ele.articleContent = ele.articleContent.replace(/<.*?>/g, '')
            if (ele.articleContent.length > 80) {
              ele.articleContent = ele.articleContent.substr(0, 80) + '...'
            }
          })
          this.loading = false
        })
      } else { this.artList = this.$route.query.list }
    },

    handlePage(val) {
      this.modeListQuery.current = val
      this.init()
      window.scrollTo(0, 0)
    },

    handleNavPage(val) {
      this.navListQuery.current = val
      this.init()
      window.scrollTo(0, 0)
    },

    handleCurrentChange(val) {
      if (this.$route.query.propName === 'mode') {
        modeArtContent({
          moduleId: this.modeId,
          current: val,
          size: '20'
        }).then((res) => {
          this.artList = res.data.data.records
          this.moreList = res.data.data
        })
      } else if (this.$route.query.propName === 'nav') {
        navArtContent({
          navigationId: this.modeId,
          current: val,
          size: '20'

        }).then((res) => {
          this.artList = res.data.data.records
          this.moreList = res.data.data
        })
      }
    },

    handleSizeChange(val) {
      if (this.$route.query.propName === 'mode') {
        modeArtContent({
          moduleId: this.modeId,
          current: val,
          size: '20'
        }).then((res) => {
          this.artList = res.data.data.records
          this.moreList = res.data.data
        })
      } else if (this.$route.query.propName === 'nav') {
        navArtContent({
          navigationId: this.modeId,
          current: val,
          size: '20'

        }).then((res) => {
          this.artList = res.data.data.records
          this.moreList = res.data.data
        })
      }
    },

    openNewTab(to) {
      const routeData = this.$router.resolve(to)
      window.open(routeData.href, '_blank')
    },

    toDetails(id, type) {
      if (type === 'modular') {
        this.openNewTab({
          name: 'Details',
          query: {
            propName: 'mode',
            articleId: id
          }
        })
      } else if (type === 'navigation') {
        this.openNewTab({
          name: 'Details',
          query: {
            propName: 'nav',
            articleId: id
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.more-content {
  width: 800px;
  margin: 20px auto;
  min-height: calc(100vh - 200px);
  min-width: 1200px;
  padding: 10px;
}

.article {

  overflow: hidden;
}
.catalog{
  margin-top: 20px;
  font-weight: bolder;
  font-size: 22px;
}
.article-item {
  width: 100%;
  margin-bottom: 25px;
  cursor: pointer;
}

.article-item > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.article-name {
  font-size: 18px;
  font-weight: bolder;
}

.article-time {
  font-size: 12px;
  color: #999;
}

.article-content {
  width: 100%;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
}

.artline {
  width: 100%;
  height: 0;
  margin-top: 18px;
  border-bottom: 1px solid #ccc;
}
</style>
